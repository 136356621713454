var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('default-template', [_c('v-layout', {
    attrs: {
      "justify-center": "",
      "align-start": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100wv"
    }
  }, [_c('div', {
    staticClass: "sub-visual-container sv1"
  }, [_c('div', {
    staticClass: "sub-visual-wrapper"
  }, [_c('h1', {
    staticClass: "sub-visual--title"
  }, [_vm._v(_vm._s(_vm.siteInfo.serviceNameKo || _vm.siteInfo.projectNameKo) + " 소개")])])]), _c('div', {
    staticClass: "sub-container sub-container__new",
    staticStyle: {
      "max-width": "1230px",
      "width": "100%",
      "margin": "0 auto"
    }
  }, [_c('div', {
    staticClass: "sub-section"
  }, [_c('div', {
    staticClass: "sub-section-introduce d-flex flex-column align-center"
  }, [_c('div', {
    staticClass: "section-logo"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ticketmania/intro-logo.png",
      "alt": "logo"
    }
  })], 1), _c('div', {
    staticClass: "text-area"
  }, [_c('h3', {
    staticClass: "text-area__tit"
  }, [_vm._v("안녕하세요 "), _c('br', {
    staticClass: "d-flex d-none d-sm-none d-md-none"
  }), _vm._v("모바일쿠폰 전문 쇼핑몰 티켓매니아 입니다.")]), _c('p', {
    staticClass: "text-area__sentence"
  }, [_c('span', [_vm._v("도서출판 브랜드인 (주)티켓매니아는 온라인 서점 및 오프라인 매장을 통하여"), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 국내 유수의 출판사와 제휴하여 양질의 도서를 공급하고 있습니다. "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("현재 약 3만 여종의 도서를 보유하고 있으며,"), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 앞으로도 고객 여러분께 더 좋은 품질의 도서를 제공하기 위하여 끊임없이 노력하겠습니다.")]), _c('span', [_vm._v("저희 (주)티켓매니아는 어린이전집 및 단행본 도서 도소매 전문업체로서"), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 전국 유명서점들과 제휴하여 좋은책을 저렴한 가격으로 공급해 드리고 있습니다.")]), _c('span', [_vm._v("저희 (주)티켓매니아는 도서 및 전집 도소매 전문업체로서"), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 고객 여러분께 항상 최상의 상품만을 공급해 드리고자 끊임없이 노력하고 있습니다.")]), _c('span', [_vm._v("저희 (주)티켓매니아는 도서 및 전집 도소매 전문업체로서"), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 고객 여러분께 항상 최상의 상품만을 공급해 드리고자 끊임없이 노력하고 있습니다.")]), _c('span', [_vm._v("저희 상품권 티켓매니아는 도서문화 해피머니상품권 등 각종 상품권을 전문적으로 취급하는 업체입니다."), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 고객님이 원하시는 모든 종류의 상품권을 가장 저렴한 가격으로 신속하게 공급해 드릴 것을 약속드립니다.")]), _c('span', [_vm._v("우리나라 최초의 온라인 도서상품권인 '티켓매니아'는 인터넷 서점과의 제휴를 통해"), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 전국 어디서나 편리하게 사용할 수 있는 국내 유일의 온ᆞ오프라인 겸용 도서상품권입니다.")])])]), _c('div', {
    staticClass: "section-bg"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/ticketmania/intro-bg.png",
      "alt": "logo"
    }
  })], 1)])])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }